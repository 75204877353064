import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const NotFound = ({ data, location }) => {
    let global = data.contentfulWebsite;
    let cr = data.contentfulCommunityResources;
    return (
        <Layout pathname={location.pathname}>
            <Seo metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-content o-container o-content-container u-text-center">
                <img className="u-border" alt={cr.title} src={cr.image.resize.src} />
                <h1>{cr.title}</h1>
                {cr.cards.map((card) => {
                    return (
                        <>
                            <img className="u-border" alt={cr.title} src={card.image.resize.src} />
                            <div>{documentToReactComponents(JSON.parse(card.biCommunityResource.raw))}</div>
                        </>
                    );
                })}
            </div>
        </Layout>
    );
};

export default NotFound

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Black Ice"}) {
        author
        description
        title
        pageNotFoundMessage {
            raw
        }
        shareImage {
            file {
                url
            }
        }
    }
    contentfulCommunityResources(id: {eq: "b3f18961-9987-59c8-9433-65cb4399a625"}) {
        title
        image {
            resize(width: 1080) {
                src
            }
        }
        cards {
            biCommunityResource {
                raw
            }
            image {
                resize(width: 500) {
                    src
                }
            }
        }
    }
}
`;